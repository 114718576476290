.report {
  .fdl {
    text-align: center;
    padding: 8px 0px;
    font-size: 20px;
  }
  .number {
    font-weight: 700;
    color: #2c4be5;
  }
  .backGroundTitle {
    border-radius: 8px;
    padding: 3px;
    color: #fff;
  }
  .fa {
    background: #2c4dfb;
    margin-bottom: 20px;
  }
  .fb {
    background: #6b11b1;
    margin-bottom: 12px;
    width: 160px;
  }
  .tabsContent {
    border-right: 1px solid #ccc;
    text-align: center;
    padding: 0px 12px;
  }
  .borderBottom {
    border-bottom: 1px solid #efefef;
    height: 28px;
    padding-top: 12px;
    font-size: 12px;
  }
  .title {
    font-size: 12px;
  }
  .am-icon {
    width: 50px;
    height: 50px;
  }
  .zz {
    text-align: center;
    color: #a19e9e;
    font-size: 10px;
  }
}
.bgWhite {
  background-color: #fff;
}
.bgLight {
  // width: ;
  background-color: #189ded;

  // background: linear-gradient(left, white, blue);
  // background: linear-gradient(top left, white, blue); /*渐变从左上角到右下角*/
  // background: -ms-linear-gradient(top left, white, blue);
  // background: -webkit-linear-gradient(top left, white, blue);
  // background: -moz-linear-gradient(top left, white, blue);

  color: '#fff';
}
